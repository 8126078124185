import { Link } from "gatsby";
import React from "react";
import { css } from "@emotion/react";
import { Div } from "@components/Markup";
import { BLACK } from "../styles/colors";

const BlogPostFilters = ({ fragen, news, downloads, projekte }) => {
  const cats = [
    { ck: "113-fragen", cv: "113 Fragen" },
    { ck: "news", cv: "News" },
    { ck: "downloads", cv: "Downloads" },
    { ck: "projekte", cv: "Projekte" },
    { ck: "250-dinge", cv: "250 Dinge" },
  ];
  return (
    <Div
      flex
      mb="md"
      css={css`
        flex-wrap: wrap;
        margin: 0px -5px;
        margin-bottom: 32px;

        @media (min-width: 768px) {
          justify-content: center;
        }
      `}
    >
      {cats.map(({ ck, cv }) => {
        return (
          <Link
            to={`/blog/${ck}`}
            css={css`
              line-height: 1.35em;
              font-family: "lucida-sans";
              font-size: 0.7rem;
              text-transform: uppercase;
              letter-spacing: 6%;
              background: transparent;
              border: none;
              outline: none !important;
              position: relative;
              cursor: pointer;
              height: 48px;
              border: 2px solid ${BLACK};
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0px 16px;

              @media (min-width: 992px) {
                height: 64px;
                font-size: 0.85rem;
              }
              &:hover {
                background-color: ${BLACK};
                color: white;
              }
              margin: 0px 4px 8px;
            `}
          >
            {cv}
          </Link>
        );
      })}
    </Div>
  );
};

export default BlogPostFilters;
