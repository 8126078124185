import React from "react";
import { Title } from "@components/Typography";
import { css } from "@emotion/react";
import { Div, Container } from "@components/Markup";
import ScrollIndicator from "./ScrollIndicator";
import HeroGallery from "./HeroGallery";

const SliderHero = ({ pageTitle, pageSlogan, items }) => {
  return (
    <Div>
      {items.length > 0 && (
        <HeroGallery items={items}>
          <Div
            flex
            ai="flex-end"
            jc="space-between"
            css={css`
              width: calc(100% - 20px);
              @media (min-width: 768px) {
                width: calc(100% - 50px);
              }
            `}
          >
            <Div
              css={css`
                max-width: 90%;
                @media (max-width: 991px) {
                  display: none;
                }
              `}
            >
              <Title size="xl" tag="h1" uppercase>
                {pageTitle}
              </Title>
              <Title size="md">{pageSlogan}</Title>
            </Div>
            <ScrollIndicator />
          </Div>
        </HeroGallery>
      )}
      <Div
        css={css`
          @media (min-width: 992px) {
            display: none;
          }
        `}
      >
        <Container>
          <Title size="xl" tag="h1" uppercase>
            {pageTitle}
          </Title>
          <Title size="md" pb="lg">
            {pageSlogan}
          </Title>
        </Container>
      </Div>
    </Div>
  );
};

export default SliderHero;
